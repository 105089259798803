<template>
  <div>
    <el-dialog :visible.sync="sta.show" title="导入新物品" >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>下载模板：</b></label>
            <a href="/files/template/mat_stock_imp.xls" target="_blank" >点击下载导入模板</a>
          </div>
          <div style="color: red; margin-bottom: 10px;">注：上传的zip文件中包括，表格和图片文件。直接打包，不可以有文件夹。</div>
          <div class="form-group">
            <el-upload drag action="/fx/file/upload/zip" :file-list="file_list" :limit="1" :on-success="upload_success" :on-exceed="over_limit">
              <i class="el-icon-upload" />
              <div class="el-upload__text"><em>点击上传</em>,只能上传zip文件，且不超过10MB</div>
            </el-upload>
          </div>
          <div class="form-group" v-if="err_guid!=null">
            <label><b></b></label>
            <el-alert type="error" effect="dark" :closable="false" show-icon title="导入文件有错误">
              <a :href="'/fx/file/download/'+err_guid" target="_blank" style="color: #fff; font-size: 14px; text-decoration: underline">点击下载错误记录</a>
            </el-alert>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">开始导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      file_list:[],
      file_guid:null,
      err_guid:null
    }
  },
  created() {

  },
  methods:{
    init(){
      this.sta={show:true,loading:false}
      this.file_list=[];
      this.file_guid=null;
      this.err_guid=null;
    },
    over_limit(){
      this.whale.toast.err("只能上传一个文件");
    },
    upload_success(resp){
      if (resp.SUCCESS){
        this.file_guid=resp.DATA.GUID
      }else{
        this.whale.toast.err(resp.MESSAGE);
      }
    },
    submit(){
      if (this.file_guid==null){
        this.whale.toast.err("请在文件上传完成后，再导入");
        return;
      }
      let self=this;
      this.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/MAT/StockApi/ImportWithPicture",
        data:{FILE_GUID:self.file_guid},
        completed:function (m){
          self.sta.loading=false;
          if (m.DATA!=null){
            self.err_guid=m.DATA;
            self.file_list=[];
            self.file_guid=null;
          }else{
            self.sta.show=false;
            self.$emit("on-saved");
          }
        }
      })
    }
  }
}
</script>
<style>
.el-dialog__title{ color: #fff}
</style>