<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shopping-cart-full"> </i> 物料管理 | 库存信息</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增物品</el-button>

            <el-button type="warning" @click="imp(true)">导入物品(仅表格)</el-button>
            <el-button type="primary" @click="imp(false)">导入物品(带图片)</el-button>
            <!-- <form id="fm_export" method="post" action="/mat/stock/export" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CAT_ID" v-model="grid.sea.CAT_ID" />
              <input type="hidden" name="STA" v-model="grid.sea.STA" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态">
                <el-option label="正常" value="1" />
                <el-option label="禁用" value="2" />
              </el-select>
              <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类" clearable @change="filter"
                style="margin-left: 5px" :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN', checkStrictly: true }" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column label="图片" width="50" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-if="scope.row.PIC_NAME" style="width: 50px; margin-left: -10px; margin-top: 5px"
                    :src="scope.row.PIC_URL" :preview-src-list="[scope.row.PIC_URL]" />
                </template>
              </el-table-column>
              <el-table-column prop="ID" label="编号" width="80" align="center" />
              <el-table-column prop="CAT_NAME" label="分类全称" width="200" align="center" />
              <el-table-column prop="NAME" label="名称" width="200" />
              <el-table-column label="单价(元)" width="100" align="center">
                <template slot-scope="scope">
                  ￥{{ scope.row.PRICE.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="数量" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.BALANCE }} ({{ scope.row.FUNIT }})
                </template>
              </el-table-column>
              <el-table-column label="金额(元)" width="100" align="center">
                <template slot-scope="scope">
                  ￥{{ scope.row.AMOUNT.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="STA_TXT" label="状态" width="60" align="center" />
              <el-table-column prop="LOWER_LIMIT" label="低值通知" width="80" align="center" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="stock_edit" @on-saved="getList" />
    </div>
    <div>
      <IMP ref="stock_imp" @on-saved="filter" />
    </div>
    <div>
      <IMP2PIC ref="stock_imp_pic" @on-saved="filter" />
    </div>
  </div>
</template>
<style>
.el-radio {
  padding-top: 10px
}
</style>
<script>
import Edit from "./edit";
import IMP from './imp'
import IMP2PIC from './imp2pic'
import Wgrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid, Edit, IMP, IMP2PIC },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          STA: "1"
        },
        ls: [],
        total: 0,
        loading: false
      },
      cat: {
        ls: [],
        val: []
      },
    }
  },
  created() {
    this.getCat();
    this.getList();
  },
  methods: {
    filter() {
      let n = this.cat.val.length;
      this.grid.sea.CAT_ID = n > 0 ? this.cat.val[n - 1] : 0;
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/StockApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    getCat() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls = its.DATA;
        }
      })
    },
    rowEdit(r) {
      this.$refs.stock_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/StockApi/Delete",
          data: { ID: r.ID },
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
    imp(onlyExl = true) {
      if (onlyExl)
        this.$refs.stock_imp.init();
      else
        this.$refs.stock_imp_pic.init();
    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('CAT_ID', this.grid.sea.CAT_ID || '')
      formData.append('STA', this.grid.sea.STA || '')
      this.whale.remote.getExport({
        url: "/api/School/MAT/MStock/Export",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)

          jsFileDownload(its, '导出物料管理库存信息.xls')
          self.grid.loading = false;
        }
      })
    }
  }
}
</script>

